<template>
    <div class="gallery imageupload">
      <div class="add-photos-button is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-relative is-z-2">
        <DingGallery :class="{'is-absolute':true,'photoEditModeOn':editModeOn}" @imagetouchdown="handlePictureTouched" :imageArray="previewImageArray" :imageInFocus="imageInFocus" />
        <div class="image-upload-buttons is-flex is-flex-direction-column is-align-items-flex-end" v-if="buttonsVisible">
            <label class="button has-min-width-fit-content is-rounded is-third-width has-background-purple-1 has-text-light has-border-black has-text-weight-bold is-z-2" for="takephotosC">
              Take Photos
            </label>
            <input id="takephotosC" name="fileupload" class="button" type="file" multiple accept="image/*" capture="environment" @change="handlePictureInput">
            <label class="button has-min-width-fit-content is-rounded is-third-width  has-background-purple-1 has-text-light has-border-black has-text-weight-bold is-z-2" for="uploadphotosC">
              Upload Photos
            </label>
            <input id="uploadphotosC" name="fileupload" class="button" type="file" multiple accept="image/*" @change="handlePictureInput">
            <Popup :unfoldUp=true :unfoldRight=true :toggle='togglePictureLinkPopUp'>
                  <template v-slot:anchor>
                     <button style="min-height:2.5em" class="button has-min-width-fit-content is-rounded is-third-width has-background-purple-1 has-text-light has-border-black has-text-weight-bold is-z-2 mt-1 mb-0">
                        Link Photos
                      </button>
                  </template>
                  <template v-slot:content>
                      <input ref="newPictureLink" type="text" class="input" placeholder="hyperlink to photo" @keypress.enter="handlePictureLink">
                      <button class="button m-1" @click="handlePictureLink">add</button>
                  </template>
            </Popup>
            <div class="mt-1 is-flex">
                <div v-if="editModeOn">
                  <div v-if="imageInFocus == null" class="mr-1 button has-min-width-fit-content is-rounded">SELECT IMAGE BY CLICK</div>
                  <button v-if="imageInFocus != null" class="mr-1 button has-min-width-fit-content is-rounded" @click="handlePictureDelete">🗑️</button>
                  <button v-if="imageInFocus != null" class="mr-1 button has-min-width-fit-content is-rounded"  @click="handleRotateClicked">⤵️</button>
                </div>
                <button :class="{'button has-min-width-fit-content is-rounded is-third-width has-text-light has-border-black has-text-weight-bold is-z-2':true,'has-background-pink-1':editModeOn,'has-background-purple-1':!editModeOn}" @click="editModeOn = !editModeOn" v-html="editModeOn ? 'END EDITING':'EDIT'"></button>
            </div>
           
        </div>
        <button class="image-upload-menu-button button is-rounded has-background-purple-1 is-size-7 has-border-black" @click="buttonsVisible = !buttonsVisible">
          <Icon class="has-text-light is-z-2" :icon="['fa', 'ellipsis-h']"/>
        </button>
      </div>
    </div>
</template>

<script>
import DingGallery from '@/components/Image/DingGallery.vue'
import Popup from '@/components/Template/Popup.vue'
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, watch } from '@vue/runtime-core'
import {isEqual} from "lodash"
import {rotateImage90DegToDataUrl} from '@/helpers.js'

export default {
  name: 'ImageUpload',
  components:{
    DingGallery,
    Popup
  },
  props: {
    startImageArray: Array,
  },
  setup(props,{emit}){
    const store = useStore();

    const buttonsVisible = ref(true);
    const editModeOn = ref(false);
    const previewImageArray = ref([])
    const imageInFocus=ref(null); 
    const imageInFocusDOMElement = ref(null);

    watch(computed(()=>props.startImageArray),()=>{
      if (props.startImageArray && !isEqual(props.startImageArray, previewImageArray.value)){
        previewImageArray.value = previewImageArray.value.concat(props.startImageArray)
      }
    })

    const handlePictureTouched = async (e) =>{
      console.log("handlePictureTouched",e);
      if (editModeOn.value){
        imageInFocus.value = e.key;
        imageInFocusDOMElement.value = e.mouseEvent.target
      }
    }

    const handlePictureDelete = async () =>{
      if (imageInFocus.value === null) return console.log("no image focused. tap image to choose.",imageInFocus.value)
      // check number of picture and then remove according entry from previewpictures and (if needed) from store
      console.log("delete image in focus",imageInFocus.value);
      let lengthOfToBeUploaded = store.state.data.photosToUpload?.length;
      let lengthOfAlreadyUploaded =  previewImageArray.value.length - lengthOfToBeUploaded;
      if (imageInFocus.value >= lengthOfAlreadyUploaded){
        previewImageArray.value.splice(lengthOfAlreadyUploaded,previewImageArray.value.length-1);
        await store.dispatch('removePhotosFromUploadList');
        return
      }
      console.log("previewImageArray.value before splice",previewImageArray.value);
      previewImageArray.value.splice(imageInFocus.value,1);
      console.log("new previeImageArray",previewImageArray.value);
      emit("imageUploadChangedPreviewImages",previewImageArray.value)
    
    }

    const handleRotateClicked = async () =>{
      if(editModeOn.value){
        // rotate picture if preview pic
        let pictureUrl = await store.dispatch("getPhotoFileNameFromPreviewIndex",imageInFocus.value);
        if (!pictureUrl) {return store.dispatch("triggerToast",{message:"upload picture before rotating!"})}
        let pictureUrlPathSplit = pictureUrl.split("/");
        let filename = pictureUrlPathSplit[pictureUrlPathSplit.length-1];
        if (!filename.startsWith("_dingsda2mex_")){
          store.dispatch("triggerToast",{message:"this picture is on another server. cannot rotate."});
          return
        }
        console.log("rotate pic",imageInFocus.value,pictureUrl);
        console.log("image object",imageInFocusDOMElement.value.naturalHeight);
        const demopic = await rotateImage90DegToDataUrl(imageInFocusDOMElement.value)
        store.dispatch("writeDataUrlIntoFileList",{dataUrl:demopic,filename:filename})
        // and change displayd picture:
        let lengthOfToBeUploaded = store.state.data.photosToUpload?.length || 0;
        let lengthOfAlreadyUploaded =  previewImageArray.value.length - lengthOfToBeUploaded;
        console.log("lengthOfAlreadyUploaded",lengthOfAlreadyUploaded);
        // if (imageInFocus.value > lengthOfAlreadyUploaded){
        //   //previewImageArray.value.splice(lengthOfAlreadyUploaded,previewImageArray.value.length-1);
        //   store.dispatch("triggerToast",{message:"upload picture first, then rotate it!"})
        //   return
        // }
        previewImageArray.value.splice(imageInFocus.value, 1, demopic);
        emit("imageUploadChangedPreviewImages",previewImageArray.value);
      }
    }

    const rescaleBase64Image = async({base64img,height=1000,width=1000})=>{
      let targetMaxHeight = height;
      let targetMaxWidth = width;

      const resizedBase64img = await new Promise((resolve) => {
        let image = new Image();
        image.src = base64img;
        image.onload = () => {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d'); 
          
          let height = image.height;
          let width = image.width;

          // if width is the largest side and exceeds maximum 
          if (width > height && width > targetMaxWidth) {
            // set width to max and rescale height to correct relationship
            height = height * targetMaxWidth/width;
            width = targetMaxWidth;
          }
          // if height is the largest side and exceeds maximum 
          if (height > width && height > targetMaxHeight) {
            // set height to max and rescale width to correct relationship
            width =  width * targetMaxHeight/height;
            height = targetMaxHeight;
          }
          
          // rescale canvas to not get borders
          canvas.height = height;
          canvas.width = width;
          // draw the image to the canvas using the new dimensions
          ctx.drawImage(image, 0, 0, width, height);
          const dataURI = canvas.toDataURL();
          resolve(dataURI)
        }
      })

      return resizedBase64img
    }

    const handlePictureInput = async (e) => {
      let files = e.target.files;
      // put into store!
      // store.dispatch("setPhotosToUpload",{files})
      let promisedFileReads = [];
      // preview all pictures in ImageGallery:
      for (let i = 0; i < files.length; i++) {
        let fileReadPromise = new Promise((resolve)=>{
          let reader = new FileReader();
            reader.onload = async function(/*readerEvent*/) {
                let rescaledImage = await rescaleBase64Image({base64img:reader.result})
                previewImageArray.value.push(rescaledImage);
                resolve();
            }
            reader.readAsDataURL(files[i]);
          })
          promisedFileReads.push(fileReadPromise);
      }
      await Promise.all(promisedFileReads);
      // put into store!
      store.dispatch("setPhotosToUploadFromBase64Array",{files:previewImageArray.value})
    }

    const newPictureLink = ref(null);
    const togglePictureLinkPopUp = ref(true);

    const handlePictureLink = () => {
      
      if (!isValidURL(newPictureLink.value.value)){
        console.error("Picture link must be valid URL");
        newPictureLink.value.value = null;
        togglePictureLinkPopUp.value = !togglePictureLinkPopUp.value
        return
      }
      previewImageArray.value.push(newPictureLink.value.value);
      newPictureLink.value.value = null;
      emit("imageUploadChangedPreviewImages",previewImageArray.value);
      togglePictureLinkPopUp.value = !togglePictureLinkPopUp.value
    }

    const isValidURL = (input) => {
      if (input.length < 1) {
        return false
      }
      let testurl;
      try {
        testurl = new URL(input);
      } catch (e) {
        return false;  
      }
      return testurl.protocol === "https:" || testurl.protocol === "http:";
    }

    return {
      buttonsVisible,
      editModeOn,
      previewImageArray,
      handlePictureInput,
      handlePictureLink,
      handlePictureDelete,
      handlePictureTouched,
      handleRotateClicked,
      newPictureLink,
      togglePictureLinkPopUp,
      imageInFocus,
      }
  }
}
</script>